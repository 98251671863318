// Static image URLs from R2

export const STATIC_IMAGES = {
  APPLE_TOUCH_ICON:
    "https://public.fjob-kyujin.com/public/apple-touch-icon.png",
  ANDROID_CHROME_192:
    "https://public.fjob-kyujin.com/public/android-chrome-192x192.png",
  ANDROID_CHROME_512:
    "https://public.fjob-kyujin.com/public/android-chrome-512x512.png",
  OG_IMAGE: "https://public.fjob-kyujin.com/public/ogimage.png",
} as const;

// link attributes
export const LINK_ATTRIBUTES = {
  ANDROID_CHROME_192: {
    rel: "icon",
    type: "image/png",
    sizes: "192x192",
  },
  ANDROID_CHROME_512: {
    rel: "icon",
    type: "image/png",
    sizes: "512x512",
  },
} as const;
